import { desktopSlots, mobileSlots } from 'config/constants/banners';

import { getCardWeight } from 'config/constants/cardsWeights';

interface SubTempAds {
  name?: keyof typeof desktopSlots | keyof typeof mobileSlots;
  isFullWidthAd: boolean;
}

interface TempGroup {
  cards: ICardProps[][];
  ads: SubTempAds[];
}

export const getGroupedLayout = ({
  mainCardType,
  otherCardsData,
  orderedBanners,
  perLayoutWeight,
  isMobile,
}: {
  mainCardType: ICardProps['type'] | 'detail' | undefined;
  otherCardsData: ICardProps[];
  orderedBanners: string[];
  perLayoutWeight: number;
  isMobile: boolean;
}) => {
  const byBillboard: TempGroup[] = [];
  let billboardCount = 0;
  let contextCount = 0;
  let currentWeight = getCardWeight(mainCardType, isMobile);
  let adCount = 0;

  if (orderedBanners[0] === 'billboard') {
    currentWeight = 100;
  }

  let tempGroup: TempGroup = {
    cards: [],
    ads: [],
  };
  let subTempCards: ICardProps[] = [];

  let subTempAds: SubTempAds = {
    name: undefined,
    isFullWidthAd: false,
  };

  let prevAdName: SubTempAds['name'];

  if (otherCardsData) {
    if (
      mainCardType &&
      (currentWeight >= perLayoutWeight || mainCardType === 'detail')
    ) {
      byBillboard.push({
        ads: [
          {
            name: isMobile ? 'top_banner' : 'billboard',
            isFullWidthAd: true,
          },
        ],
        cards: [],
      });

      currentWeight = 0;
      // billboardCount += 1 не даелаем специально
      adCount += isMobile ? 0 : 1;
    }

    otherCardsData.forEach((card: ICardProps, index) => {
      currentWeight += getCardWeight(card.type, isMobile);

      if (
        currentWeight >= perLayoutWeight ||
        (prevAdName === 'listing_spec' && currentWeight >= perLayoutWeight / 2)
      ) {
        const groupAdName =
          orderedBanners[adCount - orderedBanners.length * billboardCount];
        const isFullWidthAd =
          groupAdName === 'billboard' ||
          groupAdName === 'parallax' ||
          groupAdName === 'top_banner';

        // @ts-expect-error: Type 'string' is not assignable to type...
        prevAdName = groupAdName;

        subTempCards.push(card);
        // @ts-expect-error: Type 'string' is not assignable to type...
        subTempAds.name = groupAdName;
        subTempAds.isFullWidthAd = isFullWidthAd;

        tempGroup.cards.push(subTempCards);
        tempGroup.ads.push(subTempAds);

        if (isFullWidthAd) {
          byBillboard.push(tempGroup);
          tempGroup = {
            cards: [],
            ads: [],
          };
        }

        subTempCards = [];
        subTempAds = {
          name: undefined,
          isFullWidthAd: false,
        };

        currentWeight = 0;
        adCount += 1;

        if (groupAdName === 'context') {
          contextCount += 1;
        }

        if (
          ((groupAdName === 'billboard' || groupAdName === 'top_banner') &&
            orderedBanners.indexOf('billboard') !== 0) ||
          // eslint-disable-next-line @typescript-eslint/no-magic-numbers
          (orderedBanners.indexOf('billboard') === 0 && contextCount === 3)
        ) {
          billboardCount += 1;
          contextCount = 0;
        }
      } else {
        subTempCards.push(card);
      }

      if (otherCardsData.length - 1 === index && subTempCards.length) {
        tempGroup.cards.push(subTempCards);
        subTempCards = [];
        subTempAds = {
          name: undefined,
          isFullWidthAd: false,
        };
        tempGroup.ads.push(subTempAds);
      }

      if (otherCardsData.length - 1 === index && tempGroup.cards.length) {
        byBillboard.push(tempGroup);
      }
    });
  }

  return byBillboard;
};
